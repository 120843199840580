export default class BannerEnum {
    static BODY = 'body'
    static VIDEO = '[data-banner-video]'
    static SLIDER = '[data-banner-slider]'
    static HOME_OVERTITLES = '[data-element="header-home-overtitles"]'
    static HEADER_INTRO_MORE = '[data-element="header-intro-more"]'
    static HEADER_INTRO_MORE_BUTTON = '[data-element="header-intro-button-more"]'
    static HEADER_INTRO_LESS_BUTTON = '[data-element="header-intro-button-less"]'
    static HEADER_INTRO_MORE_DOTS = '[data-element="header-intro-more-dots"]'
}
